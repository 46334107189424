import { cinemasFetch, configFetch, statesFetch } from '../actions/creators';
import * as actions from '../actions/types';
import { getBootstrapConfig, getCinemas, getSelectedCinema, getStates } from '../reducers';

export const commonMiddleware = ({ getState, dispatch }) => next => async action => {
    // pre(action, getState, dispatch);
    next(action);
    post(action, getState, dispatch);
};

function post(action, getState, dispatch) {
    switch (action.type) {
        case actions.APP_LOADED: return onAppLoaded(action, getState, dispatch);
        case actions.CHANGE_PREFERRED_CINEMA: return onPreferredCinemaChange(action, getState, dispatch);
    }
}

function onAppLoaded(action, getState, dispatch) {
    const state = getState();
    const config = getBootstrapConfig(state);
    const states = getStates(state);
    const cinemas = getCinemas(state);

    if (!Object.keys(config).length) dispatch(configFetch());
    if (!states.length) dispatch(statesFetch());
    if (!cinemas.length) dispatch(cinemasFetch());

    onPreferredCinemaChange(action, getState, dispatch);
}

function onPreferredCinemaChange(action, getState, dispatch) {
    const state = getState();
    const cinema = getSelectedCinema(state);

    const isMarket = cinema && cinema.market === true;
    const isPlatinum = cinema && cinema.platinum === true;

    if (isMarket) {
        document.body.classList.add('theme-market');
        document.body.classList.remove('theme-platinum');
    } else if (isPlatinum) {
        document.body.classList.remove('theme-market');
        document.body.classList.add('theme-platinum');
    } else {
        document.body.classList.remove('theme-market');
        document.body.classList.remove('theme-platinum');
    }
}

export default commonMiddleware;