import React from "react";
import { connect } from "react-redux";
import MoviePoster from "../MoviePoster";
import { getLandingShowtimesData } from '../../reducers';
import ShowtimesVersionsBlocks from "../ShowtimesVersionsBlocks";
import { filterAvailableVersions, filterVersionsForDate } from "../../utils/showtimes";
import ShowtimesDateFilter from "../ShowtimesDateFilter";
import Button from '../Button';
import LinkButton from "../LinkButton";
import MDSpinner from "react-md-spinner";
import styled from "styled-components";
import { breakpoints } from '../../utils/styles'

const AreaButton = styled(LinkButton)`
    display: inline-block;
    color: inherit;
    font-size: 20px;
    margin-bottom: 1rem;
`;

const MovieHeader = styled.div`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 1.5em;
    align-items: center;

    @media screen and (min-width: ${breakpoints.desktop}px) {
        grid-template-columns: 1fr 4fr;
    }
`;

const CinemaName = styled.p`
    color: var(--primary);
    font-size: 1.25rem;
`;

const CinemaBox = styled.div`
    border-bottom: 1px solid #ddd;
    padding-top: 1.75rem;

    &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
    }

    body.theme-market & { border-color: var(--disabled-btn); }
    body.theme-platinum & { border-color: var(--primary-c); }
`;

class LandingShowtimesMovieContent extends React.Component {
    constructor(props) {
        super(props);

        this.onDateSelect = this.onDateSelect.bind(this);

        this.state = {date: null};
    }

    onDateSelect(date) {
        this.setState({date});
    }

    getCurrentDate() {
        const {data} = this.props;
        return this.state.date || (data ? data.dates[0] : null);
    }

    render() {
        const { movie } = this.props;

        return <React.Fragment>
            <MovieHeader className="px-5">
                <MoviePoster movie={movie} noCarousel noTitle alone doNotFixUrl />
                <div>
                    <Button primary to={movie.url}>Conocer más</Button>
                </div>
            </MovieHeader>
            {this.renderAreaSelector()}
            {this.renderCinemas()}
        </React.Fragment>
    }

    renderAreaSelector() {
        if (this.props.area && !this.props.data) return '';

        const {area} = this.props;

        return <AreaButton className="mx-5 mb-4" onClick={this.props.openAreaSelector}>
            {area ? (area.type === 'state' ? 'Todo ' : '') + area.entity.name : 'Selecciona tu zona'} <i className="fal fa-angle-down fa-xs pl-2"></i>
        </AreaButton>;
    }

    renderCinemas() {
        const { data } = this.props;
        const date = this.getCurrentDate();

        if (!this.props.area) return '';
        if (!data) return <div className="text-center my-5"><MDSpinner singleColor="var(--primary)"/></div>;
        if (!data.dates.length) return <p className="mx-5 pb-3">No hay horarios para esta película en la zona seleccionada.</p>;

        const cinemasBlock = data.cinemas.map(cinema => this.renderCinema(cinema));
        const hasContent = cinemasBlock.join('').length > 0;

        return <React.Fragment>
            <ShowtimesDateFilter dates={data.dates} date={date} onDateSelect={this.onDateSelect} noBg />
            <div>
                {hasContent
                    ? cinemasBlock
                    : <p className="mx-5 pb-3 pt-4">No hay horarios para esta película en la fecha seleccionada.</p>
                }
            </div>
        </React.Fragment>;
    }

    renderCinema(cinema) {
        let versions = filterAvailableVersions(cinema.versions);
        versions = filterVersionsForDate(versions, this.getCurrentDate());

        if (!versions.length) return '';

        return <CinemaBox key={cinema.id}>
            <CinemaName className="px-5">{cinema.name}</CinemaName>
            <ShowtimesVersionsBlocks movie={this.props.movie} versionsForDate={versions} columns/>
        </CinemaBox>;
    }
}

const mapStateToProps = (state, props) => ({
    data: props.pageId && props.area && props.area.entity && props.movie ? getLandingShowtimesData(state, props.pageId, props.area, props.movie.id) : null,
});

export default connect(
    mapStateToProps
)(LandingShowtimesMovieContent);