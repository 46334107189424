import * as actions from '../types';
import CMX from '../../api';
import {dialogAlert} from './dialogs';
import {isNewsLoading,getNewsLastLoadTime} from '../../reducers';

export const appLoaded = () => ({
  type: actions.APP_LOADED
});

export const moviesFetch = () => dispatch => {
  dispatch({
    type: actions.MOVIES_FETCH,
  });

  CMX.get('/movies/')
    .then(data => dispatch(moviesFetchSuccess(data)))
    .catch(error => dispatch(moviesFetchError(error)));
};

const moviesFetchSuccess = movies => ({
  type: actions.MOVIES_FETCH_SUCCESS,
  movies
});

const moviesFetchError = error => ({
  type: actions.MOVIES_FETCH_ERROR,
  error
});

export const moviesUpcomingFetch = () => dispatch => {
  dispatch({
    type: actions.MOVIES_UPCOMING_FETCH,
  });

  CMX.get('/movies/coming')
    .then(data => dispatch(moviesUpcomingFetchSuccess(data.movies)))
    .catch(error => dispatch(moviesUpcomingFetchError(error)));
};

const moviesUpcomingFetchSuccess = movies => ({
  type: actions.MOVIES_UPCOMING_FETCH_SUCCESS,
  movies
});

const moviesUpcomingFetchError = error => ({
  type: actions.MOVIES_UPCOMING_FETCH_ERROR,
  error
});

export const configFetch = () => dispatch => {
  dispatch({
    type: actions.CONFIG_FETCH,
  });

  CMX.get('/app/settings')
      .then(data => dispatch(configFetchSuccess(data)))
      .catch(error => dispatch(configFetchError(error)));
};

const configFetchSuccess = config => ({
  type: actions.CONFIG_FETCH_SUCCESS,
  config
});

const configFetchError = error => ({
  type: actions.CONFIG_FETCH_ERROR,
  error
});

export const cinemasFetch = () => dispatch => {
  dispatch({
    type: actions.CINEMAS_FETCH,
  });

  CMX.get('/cinemas/')
      .then(data => dispatch(cinemasFetchSuccess(data)))
      .catch(error => dispatch(cinemasFetchError(error)));
};

const cinemasFetchSuccess = cinemas => ({
  type: actions.CINEMAS_FETCH_SUCCESS,
  cinemas
});

const cinemasFetchError = error => ({
  type: actions.CINEMAS_FETCH_ERROR,
  error
});

export const statesFetch = () => dispatch => {
  dispatch({
    type: actions.STATES_FETCH,
  });

  CMX.get('/states/')
      .then(data => dispatch(statesFetchSuccess(data)))
      .catch(error => dispatch(statesFetchError(error)));
};

const statesFetchSuccess = states => ({
  type: actions.STATES_FETCH_SUCCESS,
  states
});

const statesFetchError = error => ({
  type: actions.STATES_FETCH_ERROR,
  error
});

export const cinemaMoviesFetch = cinemaId => dispatch => {
  dispatch({
    type: actions.CINEMA_MOVIES_FETCH,
  });

  CMX.get('/cinemas/' + cinemaId + '/movies')
    .then(data => dispatch(cinemaMoviesFetchSuccess(data, cinemaId)))
    .catch(error => dispatch(cinemaMoviesFetchError(error)));
};

const cinemaMoviesFetchSuccess = (movies, cinemaId) => ({
  type: actions.CINEMA_MOVIES_FETCH_SUCCESS,
  cinemaId,
  movies,
});

const cinemaMoviesFetchError = error => ({
  type: actions.CINEMA_MOVIES_FETCH_ERROR,
  error
});

export const areaMoviesFetch = areaId => dispatch => {
  dispatch({
    type: actions.AREA_MOVIES_FETCH,
  });

  CMX.get('/movies/area/' + areaId)
    .then(data => dispatch(areaMoviesFetchSuccess(data, areaId)))
    .catch(error => dispatch(areaMoviesFetchError(error)));
};

const areaMoviesFetchSuccess = (movies, areaId) => ({
  type: actions.AREA_MOVIES_FETCH_SUCCESS,
  areaId,
  movies,
});

const areaMoviesFetchError = error => ({
  type: actions.AREA_MOVIES_FETCH_ERROR,
  error
});

export const changePreferredCinema = (areaId, cinemaId) => ({
  type: actions.CHANGE_PREFERRED_CINEMA,
  areaId,
  cinemaId,
});

export const movieSelect = (movieId, movieUrl, history) => {
  return dispatch => {
    history.push(movieUrl);

    dispatch({
      type: actions.MOVIE_SELECT,
      movieId
    });
  };
};

export const movieUpcomingSelect = (movieId, movieUrl, history) => {
  return dispatch => {
    history.push(movieUrl);

    dispatch({
      type: actions.MOVIE_UPCOMING_SELECT,
      movieId
    });
  };
};

export const movieTabHideToggle = () => ({
  type: actions.MOVIE_TAB_HIDE_TOGGLE,
});

export const movieTabOpen = () => ({
  type: actions.MOVIE_TAB_OPEN,
});

export const movieTabClose = () => ({
  type: actions.MOVIE_TAB_CLOSE,
});

export const cinemaMovieFetch = (cinemaId, movieId) => dispatch => {
  dispatch(cinemaMovieIsLoading(true));

  CMX.get('/cinemas/' + cinemaId + '/movies/' + movieId)
      .then(data => dispatch(cinemaMovieFetchSuccess(data)))
      .then(() => dispatch(cinemaMovieIsLoading(false)))
      .catch(() => dispatch(cinemaMovieFetchError()));
};

export const cinemaMovieIsLoading = (isLoading) => ({
  type: actions.CINEMA_MOVIE_IS_LOADING,
  isLoading,
});

export const cinemaMovieFetchError = () => ({
  type: actions.CINEMA_MOVIE_FETCH_ERROR,
});

export const cinemaMovieFetchSuccess = (data) => ({
  type: actions.CINEMA_MOVIE_FETCH_SUCCESS,
  data,
});

export const showtimesFilterDateChange = (date) => ({
  type: actions.SHOWTIMES_FILTER_DATE_CHANGE,
  date,
});

export const showtimesFilterFormatChange = (formats) => ({
  type: actions.SHOWTIMES_FILTER_FORMAT_CHANGE,
  formats,
});

export const showtimesFilterFormatClear = () => ({
  type: actions.SHOWTIMES_FILTER_FORMAT_CLEAR,
});

export const authOpen = () => ({
  type: actions.AUTH_OPEN
});

export const authClose = () => {
  // @todo An action creator should not have any side effects so this should be handled in some other way...
  if (document.location.pathname.match(/^\/(registro|login)/)) {
    document.location = '/';
  }

  return {
    type: actions.AUTH_CLOSE
  }
};

export const authShowLogin = () => ({
  type: actions.AUTH_SHOW_LOGIN
});

export const authShowRecoverPass = () => ({
  type: actions.AUTH_SHOW_RECOVER_PASS
});

export const authShowResetPass = () => ({
  type: actions.AUTH_SHOW_RESET_PASS
});

export const authShowRegister = () => ({
  type: actions.AUTH_SHOW_REGISTER
});

export const authShowRegisterManual = () => ({
  type: actions.AUTH_SHOW_REGISTER_MANUAL
});

export const headerSearchTermChange = term => ({
  type: actions.HEADER_SEARCH_TERM_CHANGE,
  term,
});

export const contactFormShow = () => ({
  type: actions.CONTACT_FORM_SHOW,
});

export const contactFormSubmit = data => dispatch => {
  dispatch({
    type: actions.CONTACT_FORM_SUBMIT,
  });

  CMX.post('/contact', data)
      .then(data => dispatch(contactFormSuccess(data)))
      .catch(error => dispatch(contactFormError(error)));
};

const contactFormSuccess = data => ({
  type: actions.CONTACT_FORM_SUCCESS,
  data
});

const contactFormError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.CONTACT_FORM_ERROR,
    error
  });
};

export const jobsFormShow = () => ({
  type: actions.JOBS_FORM_SHOW,
});

export const jobsFormSubmit = data => dispatch => {
  dispatch({
    type: actions.JOBS_FORM_SUBMIT,
  });

  CMX.post('/jobs/application', data)
      .then(data => dispatch(jobsFormSuccess(data)))
      .catch(error => dispatch(jobsFormError(error)));
};

const jobsFormSuccess = data => ({
  type: actions.JOBS_FORM_SUCCESS,
  data
});

const jobsFormError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.JOBS_FORM_ERROR,
    error
  });
};

export const arcoFormShow = () => ({
  type: actions.ARCO_FORM_SHOW,
});

export const arcoFormSubmit = (data, files) => dispatch => {
  dispatch({
    type: actions.ARCO_FORM_SUBMIT,
  });

  CMX.post('/arco', data, files)
      .then(data => dispatch(arcoFormSuccess(data)))
      .catch(error => dispatch(arcoFormError(error)));
};

const arcoFormSuccess = data => ({
  type: actions.ARCO_FORM_SUCCESS,
  data
});

const arcoFormError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.ARCO_FORM_ERROR,
    error
  });
};

export const corpSalesFormShow = () => ({
  type: actions.CORP_SALES_FORM_SHOW,
});

export const corpSalesFormSubmit = data => dispatch => {
  dispatch({
    type: actions.CORP_SALES_FORM_SUBMIT,
  });

  CMX.post('/corpSales/contact', data)
      .then(data => dispatch(corpSalesFormSuccess(data)))
      .catch(error => dispatch(corpSalesFormError(error)));
};

export const specialEventsFormSubmit = data => dispatch => {
  dispatch({
    type: actions.CORP_SALES_FORM_SUBMIT,
  });

  CMX.post('/specialEvents/contact', data)
      .then(data => dispatch(corpSalesFormSuccess(data)))
      .catch(error => dispatch(corpSalesFormError(error)));
};

const corpSalesFormSuccess = data => ({
  type: actions.CORP_SALES_FORM_SUCCESS,
  data
});

const corpSalesFormError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.CORP_SALES_FORM_ERROR,
    error
  });
};

export const ieSignUpFormShow = action => ({
  type: actions.IE_SIGN_UP_FORM_SHOW,
  action
});

export const ieSignUpFormSubmit = (data, action) => ({
  type: actions.IE_SIGN_UP_FORM_SUBMIT,
  data,
  action
});

export const ieSignUpSuccess = data => ({
  type: actions.IE_SIGN_UP_SUCCESS,
  data
});

export const ieSignUpError = error => ({
  type: actions.IE_SIGN_UP_ERROR,
  error
});

export const nowFetch = (cinemaId, timeLimit) => dispatch => {
  dispatch({
    type: actions.NOW_FETCH,
    cinemaId,
  });

  CMX.get('/sessions/now/nearCinema/' + cinemaId, {movie_info: 'full', time_limit: timeLimit})
      .then(data => dispatch(nowFetchSuccess(cinemaId, data.data)))
      .catch(error => dispatch(nowFetchError(error)));
};

const nowFetchSuccess = (cinemaId, data) => ({
  type: actions.NOW_FETCH_SUCCESS,
  cinemaId,
  data,
});

const nowFetchError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.NOW_FETCH_ERROR,
    error
  });
};

export const newsFetch = () => (dispatch, getState) => {
  const state = getState();

  if (isNewsLoading(state)) {
    return;
  }

  if ((new Date()).getTime() - getNewsLastLoadTime(state) < 60 * 60 * 1000) {
    return;
  }

  dispatch({
    type: actions.NEWS_FETCH,
  });

  CMX.get('/articles/')
      .then(data => dispatch(newsFetchSuccess(data.articles)))
      .catch(error => dispatch(newsFetchError(error)));
};

const newsFetchSuccess = data => ({
  type: actions.NEWS_FETCH_SUCCESS,
  data,
});

const newsFetchError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.NEWS_FETCH_ERROR,
    error
  });
};

export const landingFetch = slug => dispatch => {
  dispatch({
    type: actions.LANDING_FETCH,
  });

  CMX.get(`/landings/${slug}`)
    .then(data => dispatch(landingFetchSuccess(slug, data)))
    .catch(error => dispatch(landingFetchError(slug, error)));
};

const landingFetchSuccess = (slug, landing) => ({
  type: actions.LANDING_FETCH_SUCCESS,
  landing,
  slug,
});

const landingFetchError = (slug, error) => ({
  type: actions.LANDING_FETCH_ERROR,
  error,
  slug,
});

export const landingShowtimesFetch = (pageId, area, movieId) => dispatch => {
  dispatch({
    type: actions.LANDING_SHOWTIMES_FETCH,
  });

  const args = {movie_id: movieId};

  args[area.type === 'state' ? 'state_id' : 'area_id'] = area.entity.id;

  CMX.get(`/landings/page/${pageId}/showtimes`, args)
    .then(data => dispatch(landingShowtimesFetchSuccess(pageId, area, movieId, data)))
    .catch(error => dispatch(landingShowtimesFetchError(error)));
};

const landingShowtimesFetchSuccess = (pageId, area, movieId, data) => ({
  type: actions.LANDING_SHOWTIMES_FETCH_SUCCESS,
  pageId, area, movieId, data
});

const landingShowtimesFetchError = error => ({
  type: actions.LANDING_SHOWTIMES_FETCH_ERROR,
  error
});

export const movieUpcomingFetch = id => dispatch => {
  dispatch({
    type: actions.MOVIE_UPCOMING_FETCH,
  });

  CMX.get(`/movies/coming/${id}`)
    .then(data => dispatch(movieUpcomingFetchSuccess(id, data)))
    .catch(error => dispatch(movieUpcomingFetchError(id, error)));
};

const movieUpcomingFetchSuccess = (id, movie) => ({
  type: actions.MOVIE_UPCOMING_FETCH_SUCCESS,
  movie,
  id,
});

const movieUpcomingFetchError = (id, error) => ({
  type: actions.MOVIE_UPCOMING_FETCH_ERROR,
  error,
  id,
});

export const fingerprintSet = id => ({
  type: actions.FINGERPRINT_SET,
  id
});

export const notificationsMovieUpcomingSubscribe = params => dispatch => {
  const {token, resourceId} = params;

  dispatch({
    type: actions.NOTIFICATIONS_MOVIE_UPCOMING_SUBSCRIBE,
  });

  CMX.post('/notifications/movies/upcoming/subscribe', {
    movie_id: resourceId,
    token,
    device: 'web',
    device_name: navigator.userAgentData.brands.map(b => b.brand + ' ' + b.version).join(' - '),
  })
      .then(res => dispatch(notificationsMovieUpcomingSubscribeSuccess(res, params)))
      .catch(error => dispatch(notificationsMovieUpcomingSubscribeError(error)));
};

const notificationsMovieUpcomingSubscribeSuccess = (res, params) => ({
  type: actions.NOTIFICATIONS_MOVIE_UPCOMING_SUBSCRIBE_SUCCESS,
  ...params,
});

const notificationsMovieUpcomingSubscribeError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.NOTIFICATIONS_MOVIE_UPCOMING_SUBSCRIBE_ERROR,
    error
  });
};

export const notificationsMovieUpcomingUnsubscribe = params => dispatch => {
  const {token, resourceId} = params;

  dispatch({
    type: actions.NOTIFICATIONS_MOVIE_UPCOMING_UNSUBSCRIBE,
  });

  CMX.post('/notifications/movies/upcoming/unsubscribe', {
    movie_id: resourceId,
    token,
    device: 'web',
  })
      .then(res => dispatch(notificationsMovieUpcomingUnsubscribeSuccess(res, params)))
      .catch(error => dispatch(notificationsMovieUpcomingUnsubscribeError(error)));
};

const notificationsMovieUpcomingUnsubscribeSuccess = (res, params) => ({
  type: actions.NOTIFICATIONS_MOVIE_UPCOMING_UNSUBSCRIBE_SUCCESS,
  ...params,
});

const notificationsMovieUpcomingUnsubscribeError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.NOTIFICATIONS_MOVIE_UPCOMING_UNSUBSCRIBE_ERROR,
    error
  });
};
